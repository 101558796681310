var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Dia")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Semana")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Mês")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"start":_vm.calendario.inicio,"end":_vm.calendario.fim,"color":"secondary","events":_vm.events,"type":_vm.type,"event-color":"#eeeeee"},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center px-1 py-0"},'div',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":event.tema.cor}},[_vm._v("mdi-circle")]),_c('div',{staticClass:"black--text mx-2"},[_c('strong',[_vm._v(" "+_vm._s(event.name)+" ")])]),_vm._l((event.redeSocial),function(rede_social,index){return _c('v-card',{key:index,staticClass:"d-flex align-center mr-1",staticStyle:{"padding":"2px"},attrs:{"width":"16px","height":"14px","flat":""}},[_c('v-img',{staticClass:"mr-1",attrs:{"contain":"","src":rede_social.icone}})],1)})],2)]}}],null,true)},[_c('span',[_vm._v("Assunto: "+_vm._s(event.name + "\n")+" - Formato: "+_vm._s(event.formato.descricao + "\n")+" - Tema: "+_vm._s(event.tema.descricao)+" ")])])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }